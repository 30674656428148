import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer.js';
import imgRoupaHomem from './roupa-homem.jpg'

const title = "Informações para Padrinhos e Madrinhas";
const liStyle = {

};
const Padrinhos = (props) => {
    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-3'} />
            <PageTitle pageTitle={title} pagesub={'Padrinhos'} />
            <section className="wpo-blog-single-section section-padding">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className={`col col-lg-8 col-12 ${props.blRight}`}>
                            <div className="wpo-blog-content">
                                <div className="post format-standard-image">
                                    <h2>{title}</h2>
                                    <p>
                                        O conteúdo desta página é aquilo que já estamos falando no grupo.
                                        Mas colocamos estas informações aqui também para maior facilidade de consulta.
                                    </p>
                                    {/* <blockquote>
                                        Combined with a handful of model sentence structures, generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.
                                    </blockquote> */}
                                    <h3>Roupas</h3>
                                    <p>
                                        Para as mulheres, a paleta de cores já está bem combinada e acho que não é necessário descrever aqui por enquanto.
                                    </p>
                                    <p>
                                        Para os homens, pedimos que usem:
                                        <ul>
                                            <li>Camisa branca</li>
                                            <li>Calça Zara Chino Skinny Caqui, disponível <a href='https://www.zara.com/br/pt/calca-chino-skinny-fit-p06786405.html?v1=364181614' target='_blank'>neste link</a>.</li>
                                            <li>Cinto marrom</li>
                                            <li>Sapato marrom</li>
                                        </ul>
                                    </p>
                                    <div className='gallery'>
                                        <img src={imgRoupaHomem} alt="" />
                                    </div>
                                    {/* <div className="gallery">
                                        <div>
                                            <img src={gl1} alt="" />
                                        </div>
                                        <div>
                                            <img src={gl2} alt="" />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer footerClass={'wpo-site-footer-s2'} />
            <Scrollbar />
        </Fragment>
    )
};
export default Padrinhos;
