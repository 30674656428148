import React from "react";

export function GoogleMap({ place }: { place: string }) {
    return <iframe
    //   width="100%"
    //   height="400"
      loading="lazy"
      allowFullScreen
      referrerPolicy="no-referrer-when-downgrade"
      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAMOfrNngIll4ugfBIm3VJP4RvNS6Vm38k
  &q=${encodeURIComponent(place)}`}
    ></iframe>
  }