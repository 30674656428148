import React, { useState } from 'react'
// import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import SimpleReactValidator from 'simple-react-validator';
import 'simple-react-validator/dist/locale/pt';

const RSVPFrom = () => {

    const formInitialState = {
        name: '',
        email: '',
        willAttend: 'will-attend'
    }
    const [forms, setForms] = useState(formInitialState);
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage',
        locale: 'pt',
    }));
    const onChange = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };
    const onChangeWillAttend = e => {
        console.log("changed will attend (complete):", e);
        console.log("changed will attend", e.target.name, "to", e.target.value);
        setForms({ ...forms, [e.target.name]: e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = e => {
        e.preventDefault();
        if (!validator.allValid()) {
            validator.showMessages();
            return
        }

        console.log("name:", forms.name);
        console.log("email:", forms.email);
        console.log("willAttend:", forms.willAttend);
        console.log("token:", token);


        validator.hideMessages();
        setForms(formInitialState);
    };
    const [token, setToken] = useState();

    return (
        <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active" >
            <div className="row">
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <input
                            value={forms.name}
                            type="text"
                            name="name"
                            onBlur={(e) => onChange(e)}
                            onChange={(e) => onChange(e)}
                            className="form-control"
                            placeholder="Seu nome" />
                        {validator.message('name', forms.name, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <input
                            value={forms.email}
                            type="email"
                            name="email"
                            onBlur={(e) => onChange(e)}
                            onChange={(e) => onChange(e)}
                            className="form-control"
                            placeholder="Seu email" />
                        {validator.message('email', forms.email, 'required|email')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="radio-buttons">
                        <p>
                            <input
                                type="radio"
                                id="will-attend"
                                name="willAttend"
                                onChange={onChangeWillAttend}
                                checked={forms.willAttend === 'will-attend'}
                                value="will-attend"
                            />
                            <label htmlFor="will-attend">Estarei lá!</label>
                        </p>
                        <p>
                            <input
                                type="radio"
                                id="will-not-attend"
                                name="willAttend"
                                checked={forms.willAttend === 'will-not-attend'}
                                onChange={onChangeWillAttend}
                                value="will-not-attend"
                            />
                            <label htmlFor="will-not-attend">Infelizmente não poderei</label>
                        </p>
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        {/* <ReCAPTCHA
                            className='form-control'
                            sitekey="6LfDEW8qAAAAAJRHvYg-KfLW22UqSSBtgxWdLIA8"
                            onChange={changeHandler}
                        /> */}
                        {/* <GoogleReCaptcha
                            onVerify={token => {
                                setToken(token);
                            }} /> */}
                    </div>
                </div>
                {/* <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <select
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            value={forms.guest}
                            type="text"
                            className="form-control"
                            name="guest">
                            <option>Number Of Guests</option>
                            <option>01</option>
                            <option>02</option>
                            <option>03</option>
                            <option>04</option>
                            <option>05</option>
                            <option>06</option>
                        </select>
                        {validator.message('guest', forms.guest, 'required')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <input
                            value={forms.reason}
                            type="text"
                            name="reason"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            className="form-control"
                            placeholder="What Will You Be Attending" />
                        {validator.message('reason', forms.email, 'required')}
                    </div>
                </div> */}
                {/* <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <select
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            value={forms.meal}
                            type="text"
                            className="form-control"
                            name="meal">
                            <option >Meal Preferences</option>
                            <option>Chicken Soup</option>
                            <option>Motton Kabab</option>
                            <option>Chicken BBQ</option>
                            <option>Mix Salad</option>
                            <option>Beef Ribs </option>
                        </select>
                        {validator.message('meal', forms.meal, 'required')}
                    </div>
                </div> */}
            </div>
            <div className="submit-area">
                <button type="submit" className="theme-btn">Enviar</button>
            </div>
        </form >
    )
}

export default RSVPFrom;