import React from 'react';
import ReactDOM from 'react-dom/client';
import App from '../src/main-component/App/App';
import reportWebVitals from './reportWebVitals';
import { ParallaxProvider } from 'react-scroll-parallax';
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import './css/font-awesome.min.css';
import './css/themify-icons.css';
import './css/animate.css';
import './css/flaticon.css';
import './sass/style.scss';

import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store/index";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <GoogleReCaptchaProvider
    //     reCaptchaKey="6LfDEW8qAAAAAJRHvYg-KfLW22UqSSBtgxWdLIA8"
    // >
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ParallaxProvider>
                    <App />
                </ParallaxProvider>
            </PersistGate>
        </Provider>
    // </GoogleReCaptchaProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
