import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Hero from '../../components/Hero';
import CoupleSection2 from '../../components/CoupleSection2/CoupleSection2';
import StorySection2 from '../../components/StorySection2/StorySection2';
import PortfolioSection from '../../components/PortfolioSection';
import RsvpSectionS3 from '../../components/RsvpSectionS3/RsvpSectionS3';
import EventSection3 from '../../components/EventSection3/EventSection3';
import BlogSection from '../../components/BlogSection/BlogSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'

const HomePage = () => {

    return (
        <Fragment>
            <Navbar />
            <Hero />
            <a id="casal"><CoupleSection2 /></a>
            <a id="historia"><StorySection2 /></a>
            <PortfolioSection />
            {/* <a id="presenca"><RsvpSectionS3/></a> */}
            <a id="local"><EventSection3 /></a>
            {/* <BlogSection /> */}
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default HomePage;